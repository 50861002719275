<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :revert="refreshDocument"
      :save="saveDocument"
      :tab="tab"
      v-on="$listeners"
    >
      <v-skeleton-loader
        type="list-item"
        v-if="!document.id && !documentIsNew"
      />
    </document-toolbar>
    <document-in-tab>
      <v-form
        class="label-infos"
        v-model="form"
        ref="form"
      >
        <v-skeleton-loader
          type="image"
          v-if="!document.id && !documentIsNew"
        />
        <v-card class="card-width">
          <v-card-title>
            {{$t('t.Label')}}
          </v-card-title>
          <v-card-text>
            <translations
              :label="$t('t.Name')"
              :translations.sync="document.name"
              :isNew="documentIsNew"
              :rules="rules"
            />
            <picker
              document-types="label-groups"
              class="mt-5 typeDocPicker"
              v-if="documentIsNew || document.group"
              :selected-id.sync="document.group"
              :clearable="false"
              :label="$t('t.LabelGroup')"
              :readonly="!documentIsNew"
              :attach="false"
            />
            <div class="mt-5">
              {{$t('t.Color')}}
              <color-picker :selected.sync="document.color" />
            </div>
            <div
              class="mt-5"
              v-if="!documentIsNew"
            >
              <label-ref :id.sync="document.id" />
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </document-in-tab>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    ColorPicker: () => import('@/components/color-picker'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Translations: () => import('@/components/translations'),
    Picker: () => import('@/components/picker'),
    LabelRef: () => import('@/components/documents-ref/label-ref.vue')
  },
  data () {
    return {
      rules: [
        v => !!v || this.$t('t.IsRequired'),
        v => v.length <= 20 || this.$t('t.MaxLength', { value: 20 })
      ]
    }
  },
  created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.LabelDetail
    },
    form: {
      get () {
        return this.tab.canSave
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    },
    id () {
      return Number(this.tab.documentId) || 0
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign({}, this.document, {
          name: {},
          color: 'red',
          group: null
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }

        this.$emit('document-title-change', { tab: this.tab, value: `${this.document.name[this.$store.getters.currentUser.culture]}` })
      }

      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    },
    labelToJSON () {
      return {
        name: this.document.name,
        group: this.document.group,
        color: this.document.color
      }
    },
    async save () {
      if (this.documentIsNew) {
        const response = await this.$http().post('/core/v6/labels', this.labelToJSON())
        const id = response?.data?.id
        if (id) {
          this.$nextTick(() => this.$router.replace({
            path: `/labels/${id}`
          }))
        }
        return response
      } else {
        return this.$http().put(`/core/v6/labels/${this.document.id}`, this.labelToJSON())
      }
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.label-infos
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  width 100%

.card-width
  width calc(100% - 12px)
  margin 0 auto 12px

.typeDocPicker
  width 50%
</style>
